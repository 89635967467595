#budget-table {}
#budget-table table { table-layout: fixed; width: 100%; }
#budget-table th { height: auto; text-align: right; padding: 5px; }
#budget-table th:first-child { width: 35ex; }
#budget-table td { height: 26px; }
#budget-table td.name { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
#budget-table td.value { text-align: right; padding: 5px; }
#budget-table td.input { text-align: right; margin: 0; padding: 0; vertical-align: middle; }
#budget-table td.input input[type=text] { text-align: right; width: 99%; height: 29px; margin: 0; padding: 0 3px; vertical-align: middle; }
#budget-table td.input input[type=text].ng-invalid { background-color: lightcoral; }
#budget-table tr.sum, td.sum { background-color: #EBEBEB; color: #222; }
#budget-table tr.main { text-transform: uppercase; }