.label-title-row > tr > th {
    padding-left: 1rem !important;
}

.title-row {
    background-color: #EBEBEB;
}

.alt-title-name-cell {
    padding-left: 3rem !important;
}

.name-cell {
    padding-left: 3rem !important;
}

.input-field {
    width: 70% !important;
}