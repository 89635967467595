.consultationSelection{
    margin-top: 50px;
    margin-left: 50px;
}

.consultationSelect{
    display: flex;
    flex-direction: row;
    justify-content: left;


}

.consultationItem{
margin-right: 2vw;

}

#measureDropdown{
    position: relative;
    overflow: scroll;
    max-height: 200px;
    padding:10px;

}
.measureDropdownDiv{
    display: flex;
    flex-direction: column;
}
.selectedMeasureItem{
    display:flex;
    flex-direction: row;
    text-align: left;
}
.selectedMeasuresScrollable{
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
    margin: 0 auto 0 1vw;
    border: solid 1px lightgrey;
    border-radius: 5px;
    padding: 10px;
    height: 200px;
}
.selectedMeasures{
    max-height: 200px;
    margin: 0 auto 0 1vw;
    overflow: hidden;
    border: solid 1px lightgrey;
    border-radius: 5px;
    padding: 10px;
    height: 200px;
}
#divToPrint{
    margin-right: 3vw;
    margin-bottom:1vh;
}

