#budget-table-2 {}
#budget-table-2 table { table-layout: auto; font-size: 12px; }
#budget-table-2 th { height: auto; text-align: right; padding: 5px; width: 12ex;  }
#budget-table-2 th:first-child { width: 15%; }
#budget-table-2 th:last-child { width: 14ex; }
#budget-table-2 td { height: 26px; position:relative; }
#budget-table-2 td.name { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding-right: 15px; }
#budget-table-2 td.name span.dropdown { margin: 0; padding: 0; float: inherit; position: inherit; background: inherit; cursor: pointer; }
#budget-table-2 td.name span.dropdown:hover { color: inherit; }
#budget-table-2 td.value { text-align: right; padding: 5px; }
#budget-table-2 td.input { text-align: right; margin: 0; padding: 0; vertical-align: middle; }
#budget-table-2 td.input input[type=text] { text-align: right; width: 99%; height: 29px; margin: 0; padding: 0 3px; vertical-align: middle; }
/*#budget-table-2 td.input input[type=text].ng-invalid { background-color: lightcoral; }*/
#budget-table-2 tr.sum, td.sum { background-color: #EBEBEB; color: #222; font-weight: bold; } /* #eee */
#budget-table-2 tr.main { text-transform: uppercase; }
#budget-table-2 span.rowIcons { position: absolute; right:5px; top:5px; }
#budget-table-2 tr.subLevel, td.subLevel { background-color: #F5F5F5; color: #222; }
