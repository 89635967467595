#company-chart-edit .dropdown-button-row{
    display:inline-block;

}


#company-chart-edit .dropdown-menu{
    width:500px;
    padding:1em;
}
#company-chart-edit .account-group{
    font-weight: bold;

}

#company-chart-edit .not-selectable-group{
    font-weight: bold;
}

#company-chart-edit .account-group:hover {
    background-color: deepskyblue;
}
#company-chart-edit .account-row{
    padding-left:20px;
}

