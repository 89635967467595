.react-datepicker {
    font-size: 1em;
}

.react-datepicker__header {
    padding-top: 0.8em;
    padding-bottom: 0.2em;
    font-size: 1em;
}

.react-datepicker__month {
    margin: 0.5em;
}

.react-datepicker__current-month {
    font-size: 1em;
}

.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
    display: inline-block;
    width: 4em;
    margin: 3px;
}

.react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
}
.react-datepicker__navigation--next {
    border-left-color: #ccc;
}
