#loading-bar .bar{
    background: #30a5ff;
    height: 5px;
  }

/*Theme Switcher*/
.theme-switcher {
    list-style: none;
    padding: 20px 10px 20px 0; }
    .theme-switcher li {
      float: left;
      text-transform: uppercase;
      color: #fff; }
      .theme-switcher li span {
        margin-right: 10px; }
      .theme-switcher li a.theme-btn {
        color: #fff;
        background: none;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0 2px;
        text-indent: -4999px;
        border-radius: 499px;
        border: 2px solid #fff;
        border: 2px solid rgba(255, 255, 255, 0.5);
        opacity: .8; }
      .theme-switcher li a.theme-btn:hover {
        border: 2px solid #fff;
        border: 2px solid rgba(255, 255, 255, 0.75);
        opacity: 1; }
      .theme-switcher li a.theme-btn-default {
        background: #30a5ff; }
      .theme-switcher li a.theme-btn-iris {
        background: #956bc0; }
      .theme-switcher li a.theme-btn-midnight {
        background: #3a75fb; }
      .theme-switcher li a.theme-btn-lime {
        background: #9ad251; }
      .theme-switcher li a.theme-btn-rose {
        background: #f43c68; }

  /* start Password strength bar style */
ul#strengthBar {
  display:inline;
  list-style:none;
  margin:0;
  margin-left:15px;
  padding:0;
  vertical-align:2px;
}

.point:last {
  margin:0 !important;
}
.point {
  background:#DDD;
  border-radius:2px;
  display:inline-block;
  height:5px;
  margin-right:1px;
  width:20px;
}
/* end Password strength bar style */

tr.variable {
	font-weight: bold;
}

td.subvariable {
	padding-left: 20px !important;
}

.var-value-row {
	text-align: right;

}
#field_costAccount .ui-select-choices {max-height: 400px}

.show-chart{
    font-size: 24px;
    border: 1px solid #DDD;
    border-radius:2px;
    padding: 3px 5px 3px 5px;
}
#chart{
  text-align: center;
}
.fifty{
    width: 80%;
    float: none;
    margin: 0 auto;
}
.hundred{
    width: 100%;
}
#costaccount-group-select{
    width:300px;
    display: inline-block;
}
#container {
    display:table;
    width: 100%;
    margin-top: 0px;
    padding: 0 0 0 0; /*set left/right padding according to needs*/
    box-sizing: border-box;
}

#reporting-header-row {
    height: 100%;
    display: table-row;
    padding: 0 0 0 0;
}
#costaccount-group-label{
    height:100%;
    vertical-align: bottom;
}
.no-float {
    display: table-cell;
    float: none;
}

/* 
    Quick'n'dirty fix for financials view component styles #327

    TODO: Find out why company-income-statement component 
          styles bolding isn't applied in financials view
*/
.company-income-statement .boldMe {
  font-weight: bold;
}
