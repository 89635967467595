.measure-list table {
    table-layout: fixed;
    width: 100%;
}

.measure-list th.fixed-width {
    width: 15em;
}

.measure-list td.name {
    overflow: scroll;
}