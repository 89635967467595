.AdditionButton {
    margin-bottom: 15px !important;
}

.glyphicon {
    margin-right: 5px !important;
}

.InvestmentTable > thead > tr > th {
    padding: 8px !important;
}

.DevaluationMethod {
    margin-bottom: 15px;
}

.DevaluationMethod > label {
    margin-bottom: 5px;
}

.DevaluationMethodRadios {
    display: -webkit-box;
}

.BalanceAccount {
    width: 100%;
    border: 1px solid #ddd;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.ErrorMessage {
    font-size: 12px;
    color: #ff0000;
    padding-top: 0.5rem;
}